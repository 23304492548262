import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import Header from "../components/Header/index";
import Content from "../components/Content";
import Footer from "../components/Footer";
import { Client, Databases, Query } from "appwrite";

const client = new Client();

client
  .setEndpoint("https://cloud.appwrite.io/v1")
  .setProject("65101912b5d060c46dd1");

const databases = new Databases(client);

const promise = databases.createDocument("[data01]", "[membros_ID]", [
  Query.equal("nome"),
]);

promise.then(
  function (response) {
    console.log(response);
  },
  function (error) {
    console.log(error);
  }
);

export default function Home() {
  return (
    <>
      <title>Home - Ondas Arpoador</title>
      <meta name="title" content="Ondas Arpoador" />
      <meta
        name="description"
        content="As estimativas iniciais indicam que, aproximadamente, 450 milhões de pessoas no mundo sofrem de transtornos mentais , e de problemas psicossociais, como os relacionados com o uso abusivo do álcool e das drogas. "
      />

      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://ondasarpoador.com.br/" />
      <meta property="og:title" content="Ondas Apoador" />
      <meta
        property="og:description"
        content="As estimativas iniciais indicam que, aproximadamente, 450 milhões de pessoas no mundo sofrem de transtornos mentais , e de problemas psicossociais, como os relacionados com o uso abusivo do álcool e das drogas. "
      />

      <Header />
      <div className="container">
        <Content />
      </div>
      <Footer />
    </>
  );
}
