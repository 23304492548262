import React from "react";
//import { Form } from "react-bootstrap";
//import { Button } from "react-bootstrap";
import Profile from "./img/logo.png";

import Surfista from "./img/surfista.png";
import Whatsapp from "./img/whatsapp.png";
import Surfers1 from "./img/fotosSurfers.png";
import ProfileRogerio from "./img/profile-rogerio.png";
import ProfileTito from "./img/profile-tito.png";
import Perfil01 from "./img/profile-01.jpeg";
import Perfil02 from "./img/profile-02.jpeg";
import Perfil03 from "./img/profile-03.jpeg";
import Perfil04 from "./img/profile-04.jpeg";
//import BlogImg from "./img/blog.png";
import Ondas1 from "./video/ondas.mp4";
import Ondas2 from "./video/ondas-2.mp4";
import Projeto from "./video/projeto_ondas.mp4";

import { Link } from "gatsby";
import SurfTrip from "./img/congressoabead2023.png";
import instagram from "../../img/instagram.png";
import InstagramFeed from "../InstagramFeed/InstagramFeed";

//import InstagramFeed from "react-ig-feed";
//import "react-ig-feed/dist/index.css";

const Content = () => (
  <>
    <section id="main" className="container border-box container-services">
      <div className="row">
        {}{" "}
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 border-box">
          <div>
            <p className="text-uppercase text-center text-videos">
              PROJETO ONDAS ARPOADOR
            </p>
            <video className="ratio ratio-4x3" autoPlay controls>
              <source src={Projeto} type="video/mp4" />
            </video>
          </div>
          <br />
        </div>
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-5 ">
          <p className="text-left mt-3">
            As estimativas iniciais indicam que, aproximadamente, 450 milhões de
            pessoas no mundo sofrem de transtornos mentais , e de problemas
            psicossociais, como os relacionados com o uso abusivo do álcool e
            das drogas.
            <br /> O Brasil é o País com o maior número de ansiosos crônicos
            (9,3% da população de acordo com a OMS), e aproximadamente 30
            milhões de brasileiros conhecem um familiar dependente químico.
            <br />
            A saúde mental depende da qualidade de vida, resgate das habilidades
            sociais, respeito próprio, relações interpessoais e do trabalho que
            formam a base estrutural para a construção de uma vida melhor.{" "}
            <br />
            <button className="btn btn-primary mt-4 mb-4">
              <a
                className=""
                href="https://api.whatsapp.com/send/?phone=5521991133070&text&app_absent=0"
                target="_blank"
              >
                <span style={{ fontWeight: "bold", color: "white" }}>
                  Mais Informações
                </span>
              </a>
            </button>
          </p>
          <br />
        </div>
      </div>

      <br />

      <section id="videos" className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <p className="text-uppercase text-center text-videos">
              Jorge Lucas: Ator e Dublador
            </p>
            <video className="ratio ratio-4x3" src={Ondas1} controls></video>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <p className="text-uppercase text-center text-videos">
              Marcelo Bispo: Fundador da Team Bispo(escola de surf) e ex
              surfista profissional.
            </p>

            <video className="ratio ratio-4x3" src={Ondas2} controls></video>
          </div>
        </div>
      </section>
      <br />
    </section>
    <br />

    <div className="container container-services">
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 box-center">
          <h4 className="text-center text-uppercase"></h4>
          <p className="text-center"></p>
          <img
            src={Surfers1}
            className="img-center img-fluid"
            width=""
            height=""
          ></img>
        </div>

        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 box-center">
          <br />
          <h4 style={{ fontWeight: "700" }} className="text-left text-uppercase">Quem somos</h4>
          <h5>Tito Gomes</h5>
          <p className="text-left">
            Terapeuta com mais de 25 anos de atuação na cidade do Rio de
            Janeiro. Conselheiro pela London School of Counseling and Therapy .
            Conselheiro The Tavistock Clinic Terapia Sistêmica Familiar e
            Organizacional Priory Hospital Conselheiro em Alcoolismo e
            Dependência Química NEPAD (UERJ) Curso de extensão “A Clínica das
            Toxicomania”.
          </p>
          <img
            src={ProfileTito}
            className="img-center img-fluid"
            width=""
            height=""
          ></img>
          <h5>Rogério Pessoa</h5>
          <p className="text-left">
            Conselheiro em dependência quimica desde 2016. Trabalhou na Clinica
            Evolução e no Espaço Village, nas atividades do Grupo de Prevenção a
            Recaída, Palestras Informativas sobre Uso Abusivo e Dependente de
            Álcool, Tabaco e Outras Drogas, Grupo de Reinserção Social e Grupo
            Familiar. De setembro de 2017 a meados de 2018 fez parte da
            Comunidade Terapêutica P3 , e nesse mesmo ano assumiu o cargo de
            conselheiro em dependência química na clinica Vila Verde. Nesse
            espaço atuou no Hospital Dia e na internação, capacitação mental e
            redução de danos.<br/> Professor de surf trabalhou durante dois anos no
            projeto SURF CARE.
          </p>
          <img
            src={ProfileRogerio}
            className="img-center img-fluid"
            width=""
            height=""
          ></img>
          {/*<img src={Sol} className="img-center" width="180" height="180"></img>*/}
        </div>
      </div>
    </div>

    <br />
    <div className="container container-services">
      {/*<h2 className="text-center text-uppercase">Depoimentos</h2>*/}
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
          <div class="">
            <img
              src={Perfil01}
              className="card-img-top img-circle"
              alt="Depoimento"
            ></img>
            <div className="card-body">
              <p className="card-text">
                <br/>
                <strong>Lucas Mele 11 anos </strong>
                <br />
                Surfa há 4 meses e cada vez quer surfar ondas maiores. Mesmo com
                paralisia cerebral sempre está cada vez mais independente,
                querendo remar sozinho e ter cada vez menos ajuda da equipe para
                entrar nas ondas.
              </p>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
          <div class="">
            <img
              src={Perfil02}
              className="card-img-top img-circle"
              alt="Depoimento"
            ></img>
            <div className="card-body">
              <p className="card-text">
                <br/>
                <strong>Paola Mele 7 anos</strong>
                <br />
                Surfa há 4 meses, tem excelente base, desce todas ondas sem cair
                e encanta todo mundo com seu estilo próprio.
              </p>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
          <div >
            <img
              src={Perfil03}
              className="card-img-top img-circle"
              alt="Depoimento"
            ></img>
            <div className="card-body">
              <p className="card-text">
                <br/>
                <strong>Tito Gomes 64 anos</strong>
                <br />
                Surfa há 6 meses, já consegue se divertir em condições
                favoráveis. Segue perdendo peso e muito feliz com os hábitos
                adquiridos ao se tornar surfista.
              </p>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
          <div class="">
            <img
              src={Perfil04}
              className="card-img-top img-circle"
              alt="Depoimento"
            ></img>
            <div className="card-body">
              <p className="card-text">
                <br/>
                <strong>Paula Seixas 56 anos</strong>
                <br />
                Paula já é nossa aluna há alguns anos. Faz um ano que ela se
                tornou deficiente visual e há 3 messes reaprendeu a surfar.
                Segue conquistando sua autonomia e cada vez mais feliz em seu
                processo.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <br />

      <br />
    </div>
    <br />
    <div className="container">
      <p className="text-left">
        <a
          href="https://www.instagram.com/ondasarpoador/"
          target="_blank"
          style={{ color: "#333", fontWeight: "medium", fontSize: "15px" }}
        >
          <img 
            src={instagram}
            alt="Link para o perfil do ondas arpoador no Instagram"
            title="Instagram"
          />
           <span style={{color:"#333"}}>  @ondasarpoador</span>
        </a>
      </p>

      {/*<InstagramFeed />*/}
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          
          <a
            href="https://api.whatsapp.com/send/?phone=5521991133070&text&app_absent=0"
            target="_blank"
            rel="noreferrer"
          >
            <button className="btn btn-primary mb-2">
              <img src={Whatsapp} width="28" height="28"></img>
              <span style={{ fontWeight: "Bolder" }}> 21 99113-3070 </span>
            </button>
          </a>
          <p>ondasarpoador@gmail.com </p>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 "></div>
      </div>
    </div>

    <br />
  </>
);
export default Content;
